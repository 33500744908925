var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid, reset }){return [_c('v-dialog',{attrs:{"value":_vm.show,"max-width":"1000px"},on:{"click:outside":function($event){_vm.$emit('close');
      reset();}}},[(_vm.item)?_c('v-card',{staticClass:"py-6"},[_c('v-card-title',{staticClass:"justify-center primary--text pb-8"},[_vm._v(" "+_vm._s(`Atualizar Contrato ${_vm.item.type.description}`)+" ")]),(!_vm.isLoading)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"lg":"8"}},[_c('v-text-field',{attrs:{"disabled":"","outlined":"","value":_vm.borrowerCpf + ' - ' + _vm.item.borrowerName,"label":"Tomador"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-text-field',{attrs:{"disabled":"","outlined":"","value":_vm.item.id,"label":"Contrato"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-text-field',{attrs:{"disabled":"","outlined":"","value":_vm.creditAmount,"label":"Valor total do crédito"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-text-field',{attrs:{"disabled":"","outlined":"","value":_vm.total,"label":"Valor total do empréstimo"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-text-field',{attrs:{"disabled":"","outlined":"","value":_vm.startedSimulationDate,"label":"Data contrato"}})],1)],1),((_vm.isDebtPurchase || _vm.isEasyBuyGooroo) && !_vm.isLoading)?_c('div',[_c('DebtPurchaseManage',{attrs:{"loan":_vm.item,"amount":_vm.debtsTotalValue,"isDebtPurchase":_vm.isDebtPurchase,"isEasyBuyGooroo":_vm.isEasyBuyGooroo},on:{"sum":_vm.updateDebtsTotalValue},model:{value:(_vm.debts),callback:function ($$v) {_vm.debts=$$v},expression:"debts"}})],1):_vm._e(),_c('v-row',[(
              _vm.isDebtPurchase ||
              _vm.isRefinancingOfInProgressLoans ||
              _vm.isEasyBuyGooroo
            )?_c('v-col',{staticClass:"mb-5 pb-2 mb-6 rounded-xl text-right",attrs:{"cols":"12"}},[_c('h3',[_c('strong',[_vm._v(_vm._s(_vm.isDebtPurchase || _vm.isEasyBuyGooroo ? "Total:" : "Valor do pagamento:"))]),_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.debtsTotalValue))+" ")])]):_vm._e()],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.possibleStatus,"item-value":"name","item-text":"description","disabled":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('VTextAreaWithValidation',{attrs:{"rules":"max:255","outlined":"","rows":"2","label":"Observação"},model:{value:(_vm.requestObservation),callback:function ($$v) {_vm.requestObservation=$$v},expression:"requestObservation"}})],1)],1),(!_vm.sumOfValuesRespectAmount && !_vm.isLoading)?_c('div',{staticClass:"pt-3"},[_c('AlertBox',{attrs:{"message":`👆🏾 A soma dos valores dos boletos deve ser igual à ${_vm.formatCurrency(
              _vm.maxAmount
            )}`}})],1):_vm._e()],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"px-6",attrs:{"color":"primary","large":"","disabled":invalid || _vm.isLoading || !_vm.sumOfValuesRespectAmount,"loading":_vm.isLoading},on:{"click":function($event){_vm.isLoading = true;
            _vm.handleUpdateLoanDebts();
            reset();}}},[_vm._v("Reenviar para pagamento")]),_c('v-spacer')],1)],1):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }