
import DatePicker from "@/components/DatePicker.vue";
import formatCurrency from "@/utils/formatCurrency";
import Boleto from "boleto.js";
import dayjs from "dayjs";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Loan from "../../types/loan";
import LoanDebt from "../../types/loan-debt";
import AlertBox from "@/components/AlertBox.vue";
import { cloneDeep, sumBy } from "lodash";

@Component({
  components: { DatePicker, ValidationObserver, ValidationProvider, AlertBox },
  directives: {}
})
export default class AddDebtPurchase extends Vue {
  formatCurrency = formatCurrency;

  @Prop() readonly loan!: Loan;
  @Prop() readonly isConsignet?: boolean;
  @Prop() readonly isEasyBuyGooroo?: boolean;
  @Prop() readonly isDebtPurchase?: boolean;
  @Prop() readonly amount!: number;
  @Prop() readonly selectedContracts?: Array<any>;

  debts = [] as LoanDebt[];

  allAssignorsAreEqual = false;
  bankSlipValues = 0;
  defaultAssignorName = "";
  defaultAssignorDocument = "";
  minimumDueDate = dayjs().add(5, "days").toDate();

  @Watch("selectedContracts", { immediate: true, deep: true })
  onSelectedContractsChange(): void {
    if (this.isConsignet) {
      this.updatedConsignetDebts();
    }
  }

  @Watch("defaultAssignorName")
  onDefaultAssignorNameChanged(value: string): void {
    if (this.allAssignorsAreEqual) {
      this.debts.forEach((debt) => {
        debt.assignorName = value;
      });
    }
  }

  @Watch("defaultAssignorDocument")
  onDefaultAssignorDocumentChanged(value: string): void {
    if (this.allAssignorsAreEqual) {
      this.debts.forEach((debt) => {
        debt.assignorDocument = value;
      });
    }
  }

  @Watch("debts")
  onDebtsChanged(debts: LoanDebt[]): void {
    if (this.allAssignorsAreEqual) {
      debts.forEach((debt) => {
        debt.assignorName = this.defaultAssignorName;
        debt.assignorDocument = this.defaultAssignorDocument;
      });
    }

    if (debts.length && this.sumOfDebts !== this.bankSlipValues) {
      this.bankSlipValues = this.sumOfDebts;
      this.$emit("input", debts);
      this.$emit("sum", sumBy(this.debts, "value"));
    }
  }

  async created(): Promise<void> {
    if (this.loan?.debts?.length) {
      this.debts = cloneDeep(this.loan.debts).map((debt) => {
        debt.dueDate = dayjs(debt.dueDate).format("YYYY-MM-DD");
        return debt;
      });
    }
    if (!this.debts?.length) this.addDebt();

    if (this.isConsignet && !this.isEasyBuyGooroo) {
      this.updatedConsignetDebts();
    }
  }

  addDebt(): void {
    this.debts.push({} as LoanDebt);
  }

  removeDebtAtIndex(index: number): void {
    if (this.debts?.length > 0) {
      this.debts.splice(index, 1);
    }
  }

  handleAllAssignorsAreEqualChanged(value: boolean): void {
    if (value) {
      this.debts.forEach((debt) => {
        debt.assignorName = "";
        debt.assignorDocument = "";
      });
    }
  }

  validateDigitableLine(index: number): void {
    const debt = this.debts[index];
    if (debt.digitableLine?.length < 47) {
      debt.dueDate = undefined;
      debt.value = undefined;
      debt.contract = undefined;
    }

    try {
      const bankSlip = new Boleto(debt.digitableLine);
      debt.dueDate = dayjs(bankSlip.expirationDate()).format("YYYY-MM-DD");
      debt.value = Number(bankSlip.amount());
      debt.contract = "-";
    } catch (e) {
      //
    }
    this.debts = [...this.debts];
  }

  get debtsDigitableLinesAreUnique(): boolean {
    const uniqueDigitableLines = new Set(
      this.debts.map((item: LoanDebt) => item.digitableLine)
    );
    const debtsDigitableLinesAreUnique =
      uniqueDigitableLines.size === this.debts.length;

    return debtsDigitableLinesAreUnique;
  }

  get numberOfDebts(): number {
    return this.debts?.length;
  }

  get hasReachedDebtsLimit(): boolean {
    return this.numberOfDebts >= 5;
  }

  updatedConsignetDebts(): void {
    this.debts = this.debts.filter((debt) =>
      this.selectedContracts.some(
        (contract) => contract.adf === debt.consignetAdfId
      )
    );

    this.selectedContracts.forEach((contract) => {
      const existingDebt = this.debts.find(
        (debt) => debt.consignetAdfId === contract.adf
      );
      if (!existingDebt) {
        this.debts.push({ consignetAdfId: contract.adf } as LoanDebt);
      }
    });

    this.$emit("input", this.debts);
  }

  get sumOfDebts(): number {
    const sum = this.debts
      .filter((debt) => debt.value)
      .reduce((acc, debt) => {
        return acc + Number(debt.value);
      }, 0);

    return sum;
  }
}
