export default interface LoanType {
  id: number;
  name: string;
  description: string;

  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

const payrollLoan: LoanType = {
  id: 1,
  name: "EMPRESTIMO_CONSIGNADO",
  description: "Empréstimo consignado"
};

const salaryAnticipation: LoanType = {
  id: 2,
  name: "ANTECIPACAO_DE_SALARIO",
  description: "Antecipação de salário"
};

const debtPurchase: LoanType = {
  id: 3,
  name: "COMPRA_DE_DIVIDA",
  description: "Compra de dívida"
};

const refinancingOfInProgressLoans: LoanType = {
  id: 4,
  name: "REFINANCIAMENTO_CONTRATOS_ANDAMENTO",
  description: "Refinanciamento de contratos em andamento"
};

const easyBuyGooroo: LoanType = {
  id: 5,
  name: "COMPRA_FACIL_CONSIGNADO",
  description: "Gooroo Compra Fácil Consignado"
};

export const LoanTypeEnum = {
  PAYROLL_LOAN: payrollLoan,
  SALARY_ANTICIPATION: salaryAnticipation,
  DEBT_PURCHASE: debtPurchase,
  REFINANCING_OF_IN_PROGRESS_LOANS: refinancingOfInProgressLoans,
  EASY_BUY_GOOROO: easyBuyGooroo
};
