
import FileUpload from "@/components/FileUpload.vue";
import Modal from "@/components/Modal.vue";
import StatusHistory from "@/components/loans/StatusHistory.vue";
import Loan, { loanTypesIds, loanTypesNames } from "@/types/loan";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import formatDate from "@/utils/formatDate";
import banks from "@/data/bancos";
import accountTypes from "@/data/tipos-de-conta";
import BankAcountModal from "@/components/loans/BankAcountModal.vue";
import LoanService from "../../services/loan-service";
import getErrorMessageFromApiError from "../../utils/getErrorMessageFromApiError";
import { LoanTypeEnum } from "../../types/loan-type";
import Refinancing from "../../types/refinancing";
import { RefinancingStatusEnum } from "../../types/refinancing-status";
import { sumBy } from "lodash";
import formatCurrency from "@/utils/formatCurrency";
import LoanInstallment from "@/types/loan-installment";
import { LoanStatusEnum } from "@/types/loan-status";

@Component({
  components: {
    ValidationObserver,
    Modal,
    FileUpload,
    StatusHistory,
    BankAcountModal
  }
})
export default class LoanModal extends Vue {
  @Prop() readonly item!: Loan;
  refinancing?: Refinancing;
  loadingRefinancing: boolean = false;
  loadingAdditionalLoanData: boolean = false;
  columns: string[] = [
    "id",
    "bank",
    "typeId",
    "branch",
    "checkingAccount",
    "accountType",
    "requestedAmount",
    "installmentValue",
    "numInstallments",
    "creditAmount",
    "total",
    "requestDate",
    "startedSimulationDate"
  ];
  RefinancingStatusEnum = RefinancingStatusEnum;
  LoanTypeEnum = LoanTypeEnum;
  loanTypesIds = loanTypesIds;
  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  formatDate = formatDate;
  formatCurrency = formatCurrency;
  loanService: LoanService;
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL;
  documentTypeList = {
    CNH: "CNH",
    RG_FRENTE: "RG frente",
    RG_VERSO: "RG verso",
    COMPROVANTE_RENDA: "Comprovante de renda",
    COMPROVANTE_RESID: "Comprovante de residência",
    ROSTO: "Foto do rosto"
  };
  documentStatusList = {
    RECEBIDO: "Recebido",
    AGUARDANDO_VALIDACAO: "Aguardando validação",
    RECUSADO: "Recusado",
    APROVADO: "Aprovado"
  };

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
    this.refinancing = { installmentsRefinancings: [] } as Refinancing;
  }

  async mounted(): Promise<void> {
    await this.fetchLoanAdditionalData();
    if (this.isDebtPurchase) {
      await this.fetchDebts();
    }

    if (this.isRefinancingOfInProgressLoans) {
      await this.fetchRefinancing();
    }
  }

  async fetchRefinancing(): Promise<void> {
    this.loadingRefinancing = true;
    const [error, refinancing] = await this.loanService.findRefinancingByLoanId(
      this.item.id
    );

    this.loadingRefinancing = false;
    if (error || !refinancing) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }

    this.refinancing = refinancing;
  }

  async fetchDebts(): Promise<void> {
    this.loadingRefinancing = true;
    const [error, debts] = await this.loanService.findDebtsByLoanId(
      this.item.id
    );

    this.loadingRefinancing = false;
    if (error || !debts) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }
    this.item.debts = debts;
  }

  async fetchLoanAdditionalData(): Promise<void> {
    this.loadingAdditionalLoanData = true;

    const [error, loanAdditionalData] = await this.loanService.listLoans({
      loanId: this.item.id,
      page: 1,
      limit: 1,
      loadDocuments: true,
      loadStatusHistory: true,
      loadInstallments: true,
      loadRemittance: false,
      loadRefinancing: false,
      columns: this.columns.join(",")
    });

    if (error || !loanAdditionalData) {
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }

    if (loanAdditionalData.items.length) {
      const [additionalData] = loanAdditionalData.items;
      const keysToUpdate = [
        ...this.columns,
        "statusHistory",
        "installments",
        "documents"
      ];

      keysToUpdate.forEach((key) => {
        if (additionalData[key] !== undefined) {
          this.item[key] = additionalData[key];
        }
      });

      if (this.item.statusHistory.length) {
        const thirdPartySimulationLoanHistory = this.item.statusHistory.filter(
          (item) => item.statusId === LoanStatusEnum.THIRD_PARTY_SIMULATION.id
        );

        this.item.statusHistory = this.item.statusHistory.filter(
          (item) => item.statusId !== LoanStatusEnum.THIRD_PARTY_SIMULATION.id
        );

        if (thirdPartySimulationLoanHistory.length) {
          thirdPartySimulationLoanHistory.sort((a, b) => b.id - a.id);
          this.item.statusHistory.push(thirdPartySimulationLoanHistory[0]);
        }

        this.item.statusHistory.sort((a, b) => b.id - a.id);
      }
    }

    this.loadingAdditionalLoanData = false;
  }

  close(): void {
    this.$emit("close");
  }

  getBankNameFromCode(code: string): string {
    const bank = banks.find((b) => b.value === code);
    return bank?.text || code;
  }

  getAccountTypeFromCode(code: number): string | number {
    const accountType = accountTypes.find((b) => b.value === code);
    return accountType?.text || code;
  }

  emitShowInstallment(item: LoanInstallment) {
    this.$emit("showLoanInstallment", item);
  }

  get sumOfValues(): number {
    const labelMappings = {
      [loanTypesIds.COMPRA_DE_DIVIDA]: this.sumOfDebts,
      [loanTypesIds.REFINANCIAMENTO_CONTRATOS_ANDAMENTO]:
        this.sumOfInstallmentsRefinancings
    };

    return labelMappings[this.item.typeId] || 0;
  }

  get sumOfDebts(): number {
    return sumBy(this.item?.debts, (d) => Number(d.value));
  }

  get sumOfInstallmentsRefinancings(): number {
    return sumBy(this.refinancing?.installmentsRefinancings, (r) =>
      Number(r.presentValue)
    );
  }

  get isDebtPurchase(): boolean {
    return this.item.typeId === this.loanTypesIds.COMPRA_DE_DIVIDA;
  }

  get isRefinancingOfInProgressLoans(): boolean {
    return (
      this.item.typeId === this.loanTypesIds.REFINANCIAMENTO_CONTRATOS_ANDAMENTO
    );
  }

  get isPartnerUser(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "PARTNER_MASTER" ||
      this.$store.getters["auth/authenticatedUser"]?.type == "PARTNER"
    );
  }
}
